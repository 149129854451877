import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "days" ]
  static classes = ['selected', 'available', 'inactive']
  static values = { date: Number, startDate: Number, nextUrl: String, previousUrl: String, availabilityUrl: String }

  styleDates(){
    this.daysTargets.forEach((element, index) => {
      let elementDate = parseInt(element.dataset.date);
      if (elementDate < this.startDateValue){
        element.classList.add(this.inactiveClass)
      }
      element.classList.remove(this.selectedClass)
      if (element.dataset.date == this.dateValue){
        element.classList.add(this.selectedClass)
      }
    })
  }

  dateValueChanged(){
    this.styleDates()
    const event = new CustomEvent('change', {
      bubbles: true,
      detail: { date: this.dateValue }
    });
    this.element.dispatchEvent(event);
  }

  selectDate(event) {
    event.preventDefault();
    let day = event.target.closest('[data-date]')
    if(this.startDateValue > parseInt(day.dataset.date)){
      return
    }
    this.dateValue = day.dataset.date
  }
}
