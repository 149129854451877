import { Controller } from "stimulus"

export default class extends Controller {

  static values = { params: String}
  
  connect(){
    this.element.href = window.location.href + this.paramsValue;
  }

}
