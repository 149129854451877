import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["update"]
  dateSelected(event) {
    this.updateTargets.forEach((element,index)=>{
      element.dataset.params = `date=${event.detail.date}`
      console.log('Click!')
      element.click();
    })
  }
}
